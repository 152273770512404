export enum AppPropKeys {
  mobileAppNotice = "mobile_app_notice",
  traceEnabled = "trace_enabled",
  speEnabled = "spe_enabled",
  specialPromo = "special_promo",
}

export interface AppProps {
  mobileAppNotice?: boolean;
  traceEnabled?: boolean;
  // use Stripe Payment Elements/setup intent
  speEnabled?: boolean;
  specialPromo?: string;
}
