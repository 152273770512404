import { API_BASE, APIResponse, callApi } from "./api";
import { Subscription } from "models/subscription";

export interface SubscriptionCreationArgs {
  planId: number;
  planCode: string;
  userId: number;
  source: "web" | "ios" | "stripe";
  provider: "stripe" | "apple" | "google";
  paymentToken: string;
  paymentIntentToken?: string | null;
  setupIntentToken?: string | null;
  couponCode?: string | null;
  referralCode?: string | null;
  campaignCid?: string | null;
}

export interface changePlanArgs {
  subscriptionId: number;
  planId: number;
  couponCode?: string | null;
}

export async function subscribe(
  opts: SubscriptionCreationArgs
): Promise<APIResponse<Subscription>> {
  try {
    const resp = await callApi(`${API_BASE}/subscriptions`, {
      method: "POST",
      body: opts,
    });
    return resp;
  } catch (e) {
    throw e;
  }
}

export async function updatePlan({
  subscriptionId,
  planId,
  couponCode,
}: changePlanArgs): Promise<APIResponse<Subscription>> {
  try {
    const resp = await callApi(`${API_BASE}/subscriptions/${subscriptionId}`, {
      method: "PUT",
      body: { planId, couponCode },
    });
    return resp;
  } catch (e) {
    throw e;
  }
}
