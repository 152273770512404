import { h, FunctionalComponent, Fragment } from "preact";
import { styled } from "linaria/react";
import Colors from "style/colors";
import { appRoute } from "util/routerUtil";
import { useWelcomePath } from "hooks/pathHook";
import ConfirmModal from "components/shared/ConfirmModal";
import { useEffect, useState } from "preact/hooks";
import { copyToClipboard } from "util/videoUtil";
import { useContext } from "preact/hooks";
import ToastContext from "util/context/ToastContext";
import { useBranch } from "hooks/branchHook";
import useUser from "hooks/userHook";
import { useSingleRoutine } from "hooks/videoHook";
import { useSchedule, useVideoOfDay } from "hooks/scheduleHook";
import UserUtil from "util/userUtil";
import { useCurrentUrl } from "hooks/currentUrlHook";
import { pubPathNoTrailingSlash, welcomePathId } from "config";
import { Video } from "models/video";
import { Path } from "models/path";
import { WelcomeModalType } from "models/welcomeModal";
import { useWelcomeModal } from "hooks/modalHook";
import { genSsoToken } from "services/sso";
import { useAppProps } from "hooks/appPropHook";
import { checkSafariMobile } from "util/userAgentUtil";
import { useWindowSize } from "hooks/windowHook";
import { RenderLogo } from "components/layout/RenderLogo";
import ProfileMenuMobile from "components/layout/ProfileMenuMobile";
import Button from "./Button";
import Icon, { IconTypes } from "./Icon";

const WELCOME_PATH_ID = welcomePathId ? parseInt(welcomePathId) : 0;

const Container = styled.div<{
  user: boolean;
  showMobileExperience: boolean;
  openProfileMenu: boolean;
}>`
  display: ${({ showMobileExperience }) =>
    showMobileExperience ? "block" : "none"};
  position: fixed;
  height: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ user }) => (user ? "#000000" : "#ffffff")};
  z-index: 1000;
  overflow-y: ${({ user, openProfileMenu }) =>
    user && !openProfileMenu ? "scroll" : "hidden"};

  ::-webkit-scrollbar {
    display: none !important;
  }

  > div:last-child {
    position: absolute;
    max-width: 100%;
    width: 100%;
    top: auto;
    height: auto;
    min-height: 162px;
    border-radius: 0;
    z-index: 2;
    box-shadow: none;
    background: ${({ user }) => (user ? "#000000" : "#ffffff")};

    > div:nth-child(1) {
      margin-bottom: ${({ user }) => (user ? "0" : "14px")};
      max-width: ${({ user }) => (user ? "100%" : "345px")};
      height: ${({ user }) => (user ? "272px" : "inherit")};

      span {
        font-weight: ${({ user }) => (user ? "600" : "500")};
        font-size: ${({ user }) => (user ? "20px" : "16px")};
        line-height: ${({ user }) => (user ? "26px" : "20px")};
        color: ${Colors.txtDark};
      }
    }
    > div:nth-child(2) > div {
      margin-bottom: 0 !important;
      background: #f9e643;
      border-radius: 8px;
      padding: 16px 24px !important;
      > span {
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: ${Colors.txtDark};
      }
    }
  }
`;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  padding: 0 16px;

  > div:nth-child(2) {
    order: 3;
    > div {
      margin: 0;
      background: ${Colors.bgGrey0};
      height: 1.8rem;
      width: 1.8rem;
      > div:nth-child(1) {
        > div {
          border: 1px solid #000000;
          height: 24px;
          width: 24px;
        }
      }
      > div:nth-child(2) {
        z-index: 1000;
        top: 68px;
        bottom: initial;

        > div:nth-child(1) > div {
          max-width: 185px;
          h1 {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }

      > div:nth-child(3) {
        background: ${Colors.bgDark0};
        opacity: 0.6;
      }
    }
  }

  > button {
    margin-left: auto !important;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    text-decoration: underline;
    margin-right: 16px !important;
  }
  button {
    border: 0;
    padding: 0;
    cursor: pointer;
    margin: 0;
  }
`;

export const SubscriptionHeader = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 52px;
  padding: 0 16px;
  background: #000000;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1001;

  > div:nth-child(2) {
    order: 3;
    > div {
      margin: 0;
      background: ${Colors.bgGrey0};
      height: 1.8rem;
      width: 1.8rem;
      > div:nth-child(1) {
        > div {
          border: 1px solid #000000;
          height: 24px;
          width: 24px;
        }
      }
      > div:nth-child(2) {
        z-index: 1000;
        top: 68px;
        bottom: initial;

        > div:nth-child(1) > div {
          max-width: 185px;
          h1 {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
        }
      }

      > div:nth-child(3) {
        background: ${Colors.bgDark0};
        opacity: 0.6;
      }
    }
  }

  > button {
    margin-left: auto !important;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #000000;
    text-decoration: underline;
    margin-right: 16px !important;
  }
  button {
    border: 0;
    padding: 0;
    cursor: pointer;
    margin: 0;
  }
`;

export const MainContent = styled.div<{ type: string; user: boolean }>`
  > div {
    width: 100%;
    margin-bottom: 16px;
    margin-top: ${({ user }) => (user ? "52px" : "0")};

    > img {
      width: 100%;
      height: ${({ user }) => (user ? "272px" : "260px")};
      object-fit: cover;
    }
    > h2 {
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      color: ${Colors.txtDark1};
      text-transform: initial;
      margin-left: 16px;
    }
  }
`;

export const WelcomeContainer = styled.div`
  background: #000000;
  padding: 48px 24px;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    color: #ffffff;
    text-align: center;
    font-size: 32px;
    font-weight: 500;
    line-height: 40px;
    letter-spacing: -1.44px;
    margin-bottom: 12px;
  }

  p {
    color: #d1d1d6;
    text-align: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 32px;
  }

  button {
    border-radius: 100px;
    background: #f9e643;
    width: 100%;
    padding: 16px 20px;
    margin-bottom: 32px;

    > span {
      color: #000000;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }
  }

  > span {
    color: #8e8e93;
    text-align: center;
    font-size: 14px;
    font-weight: 600;
    line-height: 16px;
  }
`;

export const SettingContainer = styled.div`
  background: #1a1a1a;
  padding: 32px 24px 64px 24px;
  margin-bottom: 0 !important;
  margin-top: 0 !important;
`;

export const DropdownContainer = styled.div`
  > div {
    > div {
      color: #8e8e93;
      font-size: 16px;
      font-weight: 600;
      line-height: 20px;
      display: flex;
      justify-content: space-between;

      > span {
        display: flex;
        align-items: center;
      }
    }
  }
`;

export const DropdownTitle = styled.div<{ active: boolean }>`
  > div {
    padding: ${({ active }) => (active ? "24px 0 16px 0" : "24px 0")};
  }
`;

export const ItemDropdown = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 0 !important;
  padding: 0 !important;

  > div {
    margin-bottom: 16px;
    color: #ffffff;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }
`;

export const SocialMedia = styled.div`
  padding: 40px 0 32px 0;
  > div {
    width: 100%;
    display: flex;
    padding: 0 24px;
    justify-content: space-between;
  }
`;

export const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Content = styled.div<{ type?: string; user?: boolean }>`
  width: 100%;
  margin-bottom: 16px;

  > div:nth-child(1) {
    position: relative;
    > img {
      width: 100%;
      height: ${({ type, user }) =>
        user ? "272px" : type === "routine" ? "312px" : "260px"};
      object-fit: cover;
    }
    h1 {
      font-weight: 600;
      font-size: 36px;
      line-height: 44px;
      color: ${Colors.txtLight};
      text-transform: initial;
      position: absolute;
      bottom: 24px;
      left: 16px;
      max-width: 260px;
    }
    > div {
      position: absolute;
      height: 100%;
      width: 100%;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.4) 100%
      );
    }
  }

  div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  > div:nth-child(2) {
    padding: 0 16px;
    border-bottom: 1px solid #8e8e93;
    height: 58px;
    background: ${Colors.bgLight1};

    h2 {
      font-weight: 600;
      font-size: 20px;
      line-height: 26px;
      color: ${Colors.txtDark1};
      text-transform: initial;
    }

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: ${Colors.txtDark1};
    }
  }

  > div:nth-child(3) {
    justify-content: initial;
    min-height: 48px;
    padding: 0 16px;
    background: ${Colors.bgLight1};

    span {
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      color: ${Colors.txtDark1};
    }
  }

  p {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: ${Colors.txtDark1};
    padding: 8px 16px 16px 16px;
    background: ${Colors.bgLight1};
  }
`;

export const Skeleton = styled.div<{ user: boolean }>`
  > div {
    height: 302px;
    width: 100%;
    margin-bottom: 32px;
    background-color: ${({ user }) => (user ? "#000000" : Colors.bgLight3)};
  }
`;

interface MobileExperienceProps {
  showMobileExperience: boolean;
}

interface MobileShareLink {
  deepLink: string;
}

export function useMobileShareLink(
  type: "home" | "path" | "routine"
): MobileShareLink {
  const { shareDeepLink } = useBranch();
  const [deepLink, setDeepLink] = useState<string>("");
  const { user } = useUser();
  const { videoOfDay } = useVideoOfDay(["home", "routine"].includes(type));
  const { welcomePath } = useWelcomePath(
    type == "path" ? WELCOME_PATH_ID : null
  );

  let thumbnailUrl = "";
  let pageData: Video | Path | null = null;

  if (type === "path") {
    thumbnailUrl = welcomePath?.mobileHeroImageMetadata?.url;
    pageData = welcomePath;
  } else {
    thumbnailUrl = videoOfDay?.thumbnail?.url || "";
    pageData = videoOfDay;
  }

  useEffect(() => {
    const getDeepLink = (thumbnailUrl: string, pageData: any) => {
      if (!pageData) return "";
      const { id, slug, title, description } = pageData;
      const deepLink = shareDeepLink(
        { id, slug, title, description, thumbnailUrl },
        type
      );

      return deepLink;
    };
    setDeepLink(getDeepLink(thumbnailUrl, pageData));
    if (
      !user &&
      typeof window !== "undefined" &&
      localStorage.getItem("deepLink")
    ) {
      appRoute("/signin");
    }
  }, [type, welcomePath]);

  return { deepLink };
}

const MobileExperience: FunctionalComponent<MobileExperienceProps> = ({
  showMobileExperience,
}: MobileExperienceProps) => {
  const { triggerToast } = useContext(ToastContext);

  const { currentURL } = useCurrentUrl();
  const { pathname: path } = currentURL;
  const isPath = /path/.test(path ?? "");
  const isRoutine = /routine/.test(path ?? "");
  const isHome = path === "/" || path === pubPathNoTrailingSlash;

  const { user } = useUser();
  const type = isHome ? "home" : isPath ? "path" : "routine";
  const { deepLink } = useMobileShareLink(type);

  const { specialPromo } = useAppProps();
  const activeSubscription = UserUtil.canAccessContent(user);
  const noSubscription = user && !user?.subscription;
  const expiredSubscription = user && user?.subscription?.state === "expired";
  const accountType =
    user && UserUtil.isActiveGroupOwner(user) ? "org-payment" : "ath-payment";
  const [openProfileMenu, setOpenProfileMenu] = useState(false);

  const handleCopyToClipboard = async () => {
    if (!deepLink) return;
    try {
      copyToClipboard(deepLink);
      triggerToast("Copied to clipboard");
    } catch (error) {
      console.error("ERROR", error);
    }
  };

  const { generateDeepLink } = useBranch();
  const getDeepLink = async () => {
    const { data: sso } = await genSsoToken();
    return generateDeepLink({ data: { ssoToken: sso?.token } });
  };

  const { welcomeModal } = useWelcomeModal();
  const { BackToIos } = WelcomeModalType;
  const [appSsoLink, setAppSsoLink] = useState("");
  const { width } = useWindowSize();
  const isSafariMobile = checkSafariMobile(width);
  const isIosJoin =
    welcomeModal === BackToIos ||
    ((!user?.signInCount || user?.signInCount <= 1) && isSafariMobile);

  useEffect(() => {
    (async () => {
      if (isIosJoin) {
        const deepLink = await getDeepLink();
        setAppSsoLink(deepLink);
      }
    })();
  }, [isIosJoin]);

  return (
    <Container
      user={activeSubscription}
      showMobileExperience={showMobileExperience}
      openProfileMenu={openProfileMenu}
    >
      {activeSubscription ? (
        <SubscriptionHeader>
          <div onClick={() => appRoute(`/`, true)}>
            {RenderLogo("Complete", "white", 120, 48)}
          </div>
          <div>
            <ProfileMenuMobile
              setDisabledScroll={(status) => setOpenProfileMenu(status)}
            />
          </div>
        </SubscriptionHeader>
      ) : (
        <Header>
          <div>{RenderLogo("Complete", "black", 85, 48)}</div>
          <div onClick={handleCopyToClipboard}>
            <button>
              <img
                src="https://optimize.romwod.com/image/upload/v1659637944/app/share-icon-dark.png"
                alt="share icon"
                width={24}
                height={24}
              />
            </button>
          </div>
          {!user && (
            <button
              onClick={() => {
                appRoute(`/signin`, true);
              }}
              style={{ order: "2" }}
            >
              Sign in
            </button>
          )}
        </Header>
      )}
      <MainContent type={type} user={activeSubscription || false}>
        {isRoutine ? (
          <MobileRoutineExperience type={type} />
        ) : isPath ? (
          <MobilePathExperience />
        ) : (
          <MobileHomeExperience isIosJoin={isIosJoin} appSsoLink={appSsoLink} />
        )}
      </MainContent>
      {!activeSubscription && (
        <ConfirmModal
          question={
            activeSubscription
              ? "Start your mobility journey in our iOS and Android apps."
              : `Better movement and heightened performance with evidence-based mobility sessions. ${
                  !expiredSubscription
                    ? `Try free for ${specialPromo ? "2 weeks" : "7 days"}, `
                    : ""
                }${!expiredSubscription ? "c" : "C"}ancel anytime.`
          }
          confirm={{
            text: activeSubscription
              ? "Open the app"
              : expiredSubscription
              ? "Join Now"
              : "Get 7 Days Free",
            onConfirm: () => {
              isIosJoin && appSsoLink
                ? (window.location.href = appSsoLink)
                : activeSubscription
                ? typeof window !== "undefined" &&
                  window.open("https://pliability.app/download")
                : expiredSubscription || (user && !noSubscription)
                ? appRoute(`/signup/${accountType}`, true)
                : appRoute(`/signup`, true);
            },
          }}
        />
      )}
    </Container>
  );
};

interface RoutinePreviewProps {
  currentVideo: Video | null;
  type: "home" | "path" | "routine";
}

const RoutinePreview: FunctionalComponent<RoutinePreviewProps> = ({
  currentVideo,
  type,
}: RoutinePreviewProps) => {
  const { setCurrentVideo } = useSingleRoutine(null);
  const { user } = useUser();
  const activeSubscription = UserUtil.canAccessContent(user);

  if (!currentVideo)
    return (
      <Skeleton user={activeSubscription}>
        <div />
      </Skeleton>
    );

  return (
    <Content type={type}>
      <div
        onClick={() => {
          appRoute(`/routine/${currentVideo?.slug}`, true);
          setCurrentVideo(currentVideo);
        }}
      >
        <img
          src={`${currentVideo?.thumbnail?.url}`}
          alt="wordmark pliability"
          width={56}
          height={13}
        />
        <h1>{currentVideo?.title || ""}</h1>
      </div>
    </Content>
  );
};

const MobileRoutineExperience = ({ type }) => {
  const { currentVideo } = useSingleRoutine();
  const { user } = useUser();
  const activeSubscription = UserUtil.canAccessContent(user);

  if (!currentVideo && !activeSubscription)
    return (
      <Skeleton user={activeSubscription}>
        <div />
      </Skeleton>
    );

  const { durationInSeconds } = currentVideo || {};
  const pad = (d) => (d < 10 ? "0" + d.toString() : d.toString());
  const minutes = durationInSeconds && pad(Math.floor(durationInSeconds / 60));

  return activeSubscription ? (
    <Fragment>
      <div>
        {currentVideo && (
          <img
            src={`${currentVideo?.thumbnail?.url}`}
            alt="wordmark pliability"
            width={56}
            height={13}
          />
        )}
      </div>
      {<WelcomeComponent />}
    </Fragment>
  ) : (
    <Content type={type}>
      <div>
        <img
          src={`${currentVideo?.thumbnail?.url}`}
          alt="wordmark pliability"
          width={56}
          height={13}
        />
        <h1>{currentVideo?.title}</h1>
        <div />
      </div>
      <div>
        <h2>Overview</h2>
        <div>
          <img
            src="https://optimize.romwod.com/image/upload/v1658232306/app/timer-icon.png"
            alt="timer icon"
            width={24}
            height={24}
            style={{ marginRight: "12px" }}
          />
          <span style={{ marginTop: "2px" }}>{minutes}min</span>
        </div>
      </div>
      <div>
        <img
          src="https://optimize.romwod.com/image/upload/v1658232300/app/person-icon.png"
          alt="person icon"
          width={24}
          height={24}
          style={{ marginRight: "12px" }}
        />
        <div>
          {currentVideo?.targetAreas?.map(
            ({ content }, index) =>
              index <= 2 && (
                <span key={index} style={{ marginRight: "4px" }}>
                  {` ${index !== 0 && index !== 5 ? " •" : ""} ${content} `}
                </span>
              )
          )}
        </div>
      </div>
    </Content>
  );
};

const MobileHomeExperience = ({ isIosJoin = false, appSsoLink = "" }) => {
  const { welcomePath } = useWelcomePath(WELCOME_PATH_ID);
  const { videoOfDay: currentVideo } = useVideoOfDay();
  const { user } = useUser();
  const activeSubscription = UserUtil.canAccessContent(user);

  if (!welcomePath && !activeSubscription)
    return (
      <Skeleton user={activeSubscription}>
        <div></div>
      </Skeleton>
    );

  return (
    <Fragment>
      <div
        onClick={() => {
          appRoute(`/path/${welcomePath.slug}`, true);
        }}
      >
        {welcomePath && (
          <img src={welcomePath?.images?.mobileHero || ""} alt="" />
        )}
        {!activeSubscription && <h2>{welcomePath.title} Path</h2>}
      </div>
      {!activeSubscription && (
        <RoutinePreview currentVideo={currentVideo} type={"home"} />
      )}
      {activeSubscription && (
        <WelcomeComponent
          home={true}
          isIosJoin={isIosJoin}
          appSsoLink={appSsoLink}
        />
      )}
    </Fragment>
  );
};

interface WelcomeComponentProps {
  home?: boolean;
  appSsoLink?: string;
  isIosJoin?: boolean;
}

const WelcomeComponent: FunctionalComponent<WelcomeComponentProps> = ({
  home,
  appSsoLink,
  isIosJoin,
}: WelcomeComponentProps) => {
  const { user } = useUser();
  const [statusManageAccount, setStatusManageAccount] = useState("");
  const [statusSupport, setStatusSupport] = useState("");

  const getDownloadAppUrl = () => {
    return navigator.vendor.search("Apple") >= 0
      ? window.open("//apps.apple.com/us/app/romwod/id1175346453")
      : window.open(
          "//play.google.com/store/apps/details?id=com.romwodllc.android"
        );
  };

  const openIntercomChat = (e) => {
    e.preventDefault();
    if (typeof window !== "undefined" && window.Intercom) {
      window.Intercom("show");
    }
  };

  return (
    <Fragment>
      <WelcomeContainer>
        <h1>Welcome to pliability</h1>
        <p>
          To start your mobility journey, download the <br /> app on your iOS
          {!isIosJoin ? " and Android device." : " device."}
        </p>
        <Button
          size="L"
          text="Open the app"
          handleClick={() => {
            if (isIosJoin && appSsoLink) {
              window.location.href = appSsoLink;
              return;
            } else {
              typeof window !== "undefined" &&
                window.open("https://pliability.app/download");
            }
          }}
        />
        <span>
          Also available on desktop browsers at{" "}
          <a href="https://pliability.com/" target="_blank" rel="noreferrer">
            pliability.com
          </a>
        </span>
      </WelcomeContainer>
      <SettingContainer>
        <DropdownContainer>
          <DropdownTitle active={statusManageAccount === "manageAccount"}>
            <div
              onClick={() => {
                if (statusManageAccount === "manageAccount") {
                  setStatusManageAccount("");
                } else {
                  setStatusManageAccount("manageAccount");
                }
              }}
            >
              <span>Manage Account</span>
              <Icon
                name={
                  statusManageAccount === "manageAccount"
                    ? IconTypes.AngleUp
                    : IconTypes.AngleDown
                }
              />
            </div>
            {statusManageAccount === "manageAccount" && (
              <Fragment>
                <ItemDropdown>
                  {user && !UserUtil.isActiveGroupOwner(user) && (
                    <div
                      onClick={() => appRoute("/settings/plans-and-payment")}
                    >
                      Plan & Payment
                    </div>
                  )}
                  <div
                    onClick={() => {
                      appRoute("/settings/security");
                    }}
                  >
                    Security
                  </div>
                  <div
                    onClick={() => {
                      appRoute("/settings/manage-profile");
                    }}
                  >
                    Edit Profile
                  </div>
                  <div
                    onClick={() => {
                      typeof window !== "undefined" && getDownloadAppUrl();
                    }}
                  >
                    Download App
                  </div>
                </ItemDropdown>
              </Fragment>
            )}
          </DropdownTitle>
          <DropdownTitle active={statusSupport === "support"}>
            <div
              onClick={() => {
                if (statusSupport === "support") {
                  setStatusSupport("");
                } else {
                  setStatusSupport("support");
                }
              }}
            >
              <span>Support</span>
              <Icon
                name={
                  statusSupport === "support"
                    ? IconTypes.AngleUp
                    : IconTypes.AngleDown
                }
              />
            </div>
            {statusSupport === "support" && (
              <Fragment>
                <ItemDropdown>
                  <div onClick={openIntercomChat}>Contact Support</div>
                  <div
                    onClick={() => {
                      window.open("https://support.pliability.com");
                    }}
                  >
                    Help Center
                  </div>
                </ItemDropdown>
              </Fragment>
            )}
          </DropdownTitle>
        </DropdownContainer>
        <SocialMedia>
          <div>
            <a
              href="https://www.facebook.com/romwod"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://optimize.romwod.com/image/upload/v1706708023/app/icons/facebook-icon.png"
                alt=""
                width={24}
                height={24}
              />
            </a>
            <a
              href="https://www.instagram.com/pliability_/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://optimize.romwod.com/image/upload/v1706708023/app/icons/instagram-icon.png"
                alt=""
                width={24}
                height={24}
              />
            </a>
            <a
              href="https://www.youtube.com/channel/UCLJ-3T8JhJ4XYR-5r8wsDLQ"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://optimize.romwod.com/image/upload/v1706708023/app/icons/youtube-icon.png"
                alt=""
                width={24}
                height={24}
              />
            </a>
            <a
              href="https://www.tiktok.com/@romwod"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="https://optimize.romwod.com/image/upload/v1706708023/app/icons/tiktok-icon.png"
                alt=""
                width={24}
                height={24}
              />
            </a>
          </div>
        </SocialMedia>
        <LogoContainer>
          <img
            src="https://optimize.romwod.com/image/upload/v1706709034/app/icons/logo-pliability-gray.png"
            alt=""
            width={56}
            height={56}
          />
        </LogoContainer>
      </SettingContainer>
    </Fragment>
  );
};

const MobilePathExperience: FunctionalComponent = ({}) => {
  const { welcomePath } = useWelcomePath(WELCOME_PATH_ID);
  const { currentWorkout } = useSchedule();
  const { setCurrentVideo } = useSingleRoutine(null);
  const { user } = useUser();
  const activeSubscription = UserUtil.canAccessContent(user);

  if (!welcomePath && !activeSubscription)
    return (
      <Skeleton user={activeSubscription}>
        <div />
      </Skeleton>
    );

  return activeSubscription ? (
    <Fragment>
      <div
        onClick={() => {
          appRoute(`/routine/${currentWorkout?.video?.slug}`, true);
          setCurrentVideo(currentWorkout?.video);
        }}
      >
        {welcomePath && (
          <img
            src={`${welcomePath?.mobileHeroImageMetadata.url}`}
            alt="wordmark pliability"
            width={56}
            height={13}
          />
        )}
      </div>
      {<WelcomeComponent />}
    </Fragment>
  ) : (
    <Content
      onClick={() => {
        appRoute(`/routine/${currentWorkout?.video?.slug}`, true);
        setCurrentVideo(currentWorkout?.video);
      }}
      user={activeSubscription}
    >
      <div>
        <img
          src={`${welcomePath?.mobileHeroImageMetadata.url}`}
          alt="wordmark pliability"
          width={56}
          height={13}
        />
        <h1>{welcomePath.title} Path</h1>
      </div>
      <Fragment>
        <div>
          <h2>Overview</h2>
          <div>
            <img
              src="https://optimize.romwod.com/image/upload/v1659637949/app/video-icon-dark.png"
              alt="video icon"
              width={24}
              height={24}
              style={{ marginRight: "12px" }}
            />
            <span>{welcomePath.videoCount} routines</span>
          </div>
        </div>
        <p>
          The perfect place to start you pliability journey. Get to know the
          poses, the purpose, and the foundation of our program as you spend
          time each day moving through these routines.
        </p>
      </Fragment>
    </Content>
  );
};

export default MobileExperience;
