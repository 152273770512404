import { FunctionalComponent, h, Fragment } from "preact";
import { styled } from "linaria/react";

import Colors from "style/colors";
import Text from "components/shared/Text";
import { DisplayOverlay } from "style/layout";
import mq from "style/breakpoints";
import Loader from "components/layout/Loader";

const Container = styled.div<{ memberModal: boolean; template?: string }>`
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 1001;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 343px;
  height: 228px;
  background-color: ${Colors.bgLight2};
  padding: 1rem;
  border-radius: ${({ memberModal }) => (memberModal ? "0.75rem" : "0.25rem")};
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 8px;

  > span {
    margin-bottom: 1rem;
  }

  ${mq.minXs} {
    height: 11.875rem;
    max-width: 420px;
  }
`;

const ConfirmContainer = styled.div<{ template?: string }>`
  background-color: ${Colors.bgDark0};
  margin-bottom: 0.5rem;
  margin-bottom: ${({ template }) =>
    template === "vertical" ? "0.5rem" : "0"} !important;
  order: 1;
  width: ${({ template }) =>
    template === "vertical" ? "100%" : "auto"} !important;
  padding: ${({ template }) =>
    template === "vertical" ? "0.75rem 1.5rem" : "10px 16px"} !important;
  align-items: center;
  justify-content: center;

  :hover {
    transition: opacity 0.25s ease-in-out;
    opacity: 0.9;
  }

  > span {
    color: ${Colors.txtLight};
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
  }
`;

const CancelContainer = styled.div<{ template?: string }>`
  background-color: ${Colors.bgLight2};
  padding: ${({ template }) =>
    template === "vertical" ? "0.75rem 1.5rem" : "10px 16px"} !important;
  width: ${({ template }) =>
    template === "vertical" ? "100%" : "auto"} !important;
  margin-right: ${({ template }) =>
    template === "vertical" ? "0" : "16px"} !important;
  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    transition: opacity 0.25s ease-in-out;
    opacity: 0.9;
  }
`;

const Header = styled.div<{ template?: string }>`
  h1 {
    font-weight: 600;
    font-size: 28px;
    line-height: 36px;
    color: ${Colors.txtDark1};
    margin-bottom: 16px;
    text-transform: initial;
  }
  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: ${Colors.txtDark};
  }
`;

const Content = styled.div<{ memberModal: boolean; template?: string }>`
  display: ${({ template }) => (template === "vertical" ? "block" : "flex")};
  justify-content: ${({ template }) =>
    template === "vertical" ? "inherit" : "flex-end"};

  > div {
    cursor: pointer;
    padding: 0.75rem 1.5rem;
    text-align: center;
    border-radius: ${({ memberModal }) => (memberModal ? "0.75rem" : "0.5rem")};
    width: 100%;
  }
`;
interface ConfirmModalProps {
  title?: string;
  question: string;
  confirm: {
    text: string;
    onConfirm?: (e) => void;
  };
  cancel?: {
    text: string;
    onCancel?: (e) => void;
  };
  template?: string;
  children?: any;
  loading?: boolean;
  unwrapped?: boolean;
  className?: string;
}

const ConfirmModal: FunctionalComponent<ConfirmModalProps> = ({
  title,
  question,
  confirm,
  cancel,
  template = "vertical",
  children,
  loading = false,
  unwrapped = true,
  className = "",
}: ConfirmModalProps) => {
  const memberModal =
    confirm.text === "Resend Invite" || confirm.text === "Delete Member";

  return (
    <Fragment>
      <Container
        memberModal={memberModal}
        template={template}
        className={className}
      >
        <Header template={template} className="header-confirm-modal">
          {title && <h1>{title}</h1>}
          <Text size="M">{question}</Text>
        </Header>
        {children}
        <Content
          template={template}
          memberModal={memberModal}
          className="buttons-confirm-modal"
        >
          <ConfirmContainer
            onClick={confirm.onConfirm}
            onTouchEnd={(e) => e.stopPropagation()}
            onTouchMove={(e) => e.stopPropagation()}
            onTouchStart={(e) => e.stopPropagation()}
            template={template}
            className="confirm-button"
          >
            {loading ? (
              <Loader
                isLoading={Boolean(loading)}
                size="S"
                unwrapped={unwrapped}
              />
            ) : (
              <Text size="M">{confirm.text}</Text>
            )}
          </ConfirmContainer>
          {cancel && (
            <CancelContainer
              onClick={cancel.onCancel}
              onTouchEnd={(e) => e.stopPropagation()}
              onTouchMove={(e) => e.stopPropagation()}
              onTouchStart={(e) => e.stopPropagation()}
              template={template}
              className="cancel-button"
            >
              <Text size="M">{cancel.text}</Text>
            </CancelContainer>
          )}
        </Content>
      </Container>
      {template === "horizontal" && (
        <DisplayOverlay
          background={"rgba(0, 0, 0, 0.4)"}
          index={1000}
          className="overlay"
        />
      )}
    </Fragment>
  );
};

export default ConfirmModal;
