import { useAction, useSelector } from "@preact-hooks/unistore";
import { useEffect, useState } from "preact/hooks";
import actions from "store/actions";
import { getPaths, getPath } from "services";
import {
  getUserPaths,
  addPath as _addPath,
  resetPath as _resetPath,
  leavePath as _leavePath,
  advancePath as _advancePath,
  videoCompletedPath as _videoCompletedPath,
  getPathBySlug,
  getPathsLite,
  getPathsTags,
  searchPaths,
} from "services/paths";
import { LitePath, Path, UserPath } from "models/path";
import useUser from "./userHook";
import { BoundAction } from "unistore";
import { shouldSearch } from "util/SearchUtil";
import { objectsDifferences } from "util/objectsDifferences";
import { SearchVideoOptions } from "models/search";

interface PathsHook {
  paths: Path[] | null;
}

interface SearchPathHook {
  searchPathResults: { data: Path[]; total: number } | null;
  pathElements: Path[] | null;
}

interface LitePathsHook {
  paths: LitePath[] | null;
  pathListTags: LitePath[] | null;
}

let fetchingPaths = false;

export function useLitePaths(
  type = "",
  tags?: string | undefined
): LitePathsHook {
  const setLitePathsAction = useAction(actions.setLitePaths);
  const { litePaths: paths } = useSelector("litePaths");
  const [pathListTags, setPathListTags] = useState<any>(null);
  const isTags = type === "tags";

  useEffect(() => {
    if ((!fetchingPaths && !paths) || (!fetchingPaths && isTags)) {
      fetchingPaths = true;
      (async () => {
        try {
          const result = await getPathsLite(false, tags || "");
          !isTags && setLitePathsAction(result.data);
          isTags && setPathListTags(result.data);
          fetchingPaths = false;
        } catch (error: any) {}
      })();
    }
  }, [tags]);
  return { paths, pathListTags };
}

let fetchingPathTags = false;

export function usePathTags(): any {
  const [pathTags, setPathTags] = useState<any>([]);
  useEffect(() => {
    if (!fetchingPathTags && !pathTags?.length) {
      fetchingPathTags = true;
      (async () => {
        try {
          const result = await getPathsTags();
          setPathTags(result.data);
          fetchingPathTags = false;
        } catch (error: any) {}
      })();
    }
  }, []);
  return { pathTags };
}

interface SinglePathHook {
  currentPath: Path | null;
}

const pathsCache: { [slug: string]: Path } = {};

let fetchingSinglePath = false;

export function useSinglePath(slug: string): SinglePathHook {
  const setCurrentPathAction = useAction(actions.setCurrentPath);
  const { currentPath } = useSelector("currentPath");
  useEffect(() => {
    if (!slug || currentPath?.slug !== slug) {
      setCurrentPathAction(null);
    }
    if (
      slug &&
      currentPath?.slug !== slug &&
      !fetchingSinglePath &&
      !pathsCache[slug]
    ) {
      (async () => {
        try {
          fetchingSinglePath = true;
          const { data: path } = await getPathBySlug(slug);
          if (path) {
            setCurrentPathAction(path);
            fetchingSinglePath = false;
          }
        } catch (error) {}
      })();
    } else if (slug && pathsCache[slug]) {
      setCurrentPathAction(pathsCache[slug]);
    }
  }, [slug]);

  return { currentPath };
}

let fetchingWelcomePath = false;
export function useWelcomePath(pathId: number | null): { welcomePath: Path } {
  const setWelcomePathsAction = useAction(actions.setWelcomePath);
  const { welcomePath } = useSelector("welcomePath");
  useEffect(() => {
    if (pathId && !fetchingWelcomePath && !welcomePath) {
      fetchingWelcomePath = true;
      (async () => {
        try {
          const result = await getPath(pathId);
          setWelcomePathsAction(result.data);
          fetchingWelcomePath = false;
        } catch (error) {}
      })();
    }
  }, [pathId]);
  return { welcomePath };
}

interface FeaturedPathsHook {
  featuredPaths: Path[] | null;
}

let fetchingFeaturedPaths = false;

export function useFeaturedPaths(): FeaturedPathsHook {
  const setFeaturedPathsAction = useAction(actions.setFeaturedPaths);
  const { featuredPaths } = useSelector("featuredPaths");
  useEffect(() => {
    if (!fetchingFeaturedPaths && !featuredPaths) {
      fetchingFeaturedPaths = true;
      (async () => {
        try {
          const result = await getPaths(true);
          setFeaturedPathsAction(result.data);
        } catch (error) {}
      })();
    }
  }, []);
  return { featuredPaths };
}

interface UserPathsHook {
  userPaths?: UserPath[] | null;
  isLoadingUserPaths?: boolean;
  addPath?(pathId: number): Promise<void>;
  resetPath?(pathId: number): Promise<void>;
  leavePath?(pathId: number): Promise<void>;
  startPath?(pathId: number): void;
  advancePath?(pathId: number): Promise<void>;
  videoCompletedPath?(pathId: number, videoId: number): Promise<void>;
}

let fetchingUserPaths = false;

export function useUserPaths(refetchUserPaths = false): UserPathsHook {
  const setUserPathsAction = useAction(actions.setUserPaths);
  const setCurrentPathAction = useAction(actions.setCurrentPath);
  const addPathAction = useAction(actions.addPath);
  const resetPathAction = useAction(actions.resetPath);
  const leavePathAction = useAction(actions.leavePath);
  const advancePathAction = useAction(actions.advancePath);
  const startPathAction = useAction(actions.startPath);
  const { userPaths } = useSelector("userPaths");
  const { user, errorRedirect } = useUser();
  const userPathsMethods = {
    addPath: (pathId: number) => addPath(addPathAction, pathId),
    resetPath: (pathId: number) => resetPath(resetPathAction, pathId),
    leavePath: (pathId: number) => leavePath(leavePathAction, pathId),
    advancePath: (pathId: number) => advancePath(advancePathAction, pathId),
    videoCompletedPath: (pathId: number, videoId: number) =>
      videoCompletedPath(advancePathAction, pathId, videoId),
    startPath: (pathId: number) => startPath(startPathAction, pathId),
  };
  const [isLoadingUserPaths, setIsLoadingUserPaths] = useState(true);

  if (!user) return { userPaths: null };

  useEffect(() => {
    if ((!fetchingUserPaths && !Array.isArray(userPaths)) || refetchUserPaths) {
      fetchingUserPaths = true;
      (async () => {
        try {
          const result = await getUserPaths();
          setUserPathsAction(result.data);
          fetchingUserPaths = false;
          setIsLoadingUserPaths(false);
        } catch (error: any) {
          errorRedirect(error);
          setIsLoadingUserPaths(false);
        }
      })();
    }
  }, []);

  const addPath = async (
    addPathAction: BoundAction,
    pathId: number
  ): Promise<void> => {
    try {
      if (!user) throw new Error("No User Logged in to add path");
      const result = await _addPath({ pathId });
      addPathAction(result.data);
    } catch (error: any) {
      errorRedirect(error);
    }
  };

  const resetPath = async (
    resetPathAction: BoundAction,
    pathId: number
  ): Promise<any> => {
    try {
      if (!user) throw new Error("No User Logged in to reset path");
      const result = await _resetPath({ pathId });
      resetPathAction(result.data);
      return result.data;
    } catch (error: any) {
      errorRedirect(error);
    }
  };

  const leavePath = async (
    leavePathAction: BoundAction,
    pathId: number
  ): Promise<void> => {
    try {
      if (!user) throw new Error("No User Logged in to leave path");
      await _leavePath({ pathId });
      leavePathAction(pathId);
    } catch (error: any) {
      errorRedirect(error);
    }
  };

  const advancePath = async (
    advancePathAction: BoundAction,
    pathId: number
  ): Promise<void> => {
    try {
      if (!user) throw new Error("No User Logged in to advance path");
      const result = await _advancePath({ pathId });
      advancePathAction(result.data);
    } catch (error: any) {
      errorRedirect(error);
    }
  };

  const videoCompletedPath = async (
    advancePathAction: BoundAction,
    pathId: number,
    videoId: number
  ): Promise<any> => {
    try {
      if (!user) throw new Error("No User Logged in to advance path");
      const result = await _videoCompletedPath({ pathId, videoId });
      advancePathAction(result.data);
      setCurrentPathAction(result.data);
      return result;
    } catch (error: any) {
      errorRedirect(error);
    }
  };

  const startPath = (startPathAction: BoundAction, pathId: number): void => {
    try {
      if (!user) throw new Error("No User Logged in to advance path");
      startPathAction(pathId);
    } catch (error: any) {
      errorRedirect(error);
    }
  };

  return { userPaths, isLoadingUserPaths, ...userPathsMethods };
}

export function useSearchPaths(
  opts?: SearchVideoOptions | null
): SearchPathHook {
  const [pathElements, setPathElements] = useState<Path[]>([]);
  const setSearchOptsAction = useAction(actions.setSearchOpts);
  const setSearchPathResultsAction = useAction(actions.setSearchPathResults);
  const { searchPathResults, searchOpts } = useSelector(
    "searchPathResults,searchOpts"
  );

  useEffect(() => {
    if (!searchPathResults) return;
    setPathElements((prevState) => [...prevState, ...searchPathResults.data]);
  }, [searchPathResults, setPathElements]);

  useEffect(() => {
    if (
      opts &&
      Object.keys(opts).length &&
      shouldSearch(opts) &&
      (!searchPathResults ||
        JSON.stringify(opts) !== JSON.stringify(searchOpts))
    ) {
      (async () => {
        try {
          const diff = objectsDifferences(opts, searchOpts);
          setSearchOptsAction(opts);

          if (!Object.keys(diff).length) return;

          if (!diff?.from) {
            setPathElements([]);
            setSearchPathResultsAction(null);
            if (opts && opts.from) {
              opts.from = 0;
            }
          }

          const { data, total } = await searchPaths(opts);
          setSearchPathResultsAction({ data, total });
        } catch (error) {}
      })();
    }
  }, [opts && JSON.stringify(opts)]);

  return { searchPathResults, pathElements };
}
